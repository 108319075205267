export const environment = {
  production: true, // set TRUE before you build and release a prod version.
  // Set your app configurations here.
  // For the list of config options, please refer to https://ionicframework.com/docs/api/config/Config/

  /*  BACKENDFULL      */
  UrlFull: "https://backend.thomas-talk.me/", // https://backend.thomas-talk.me/
  /*  APICLUBFIBEX      */
  apiTest: "https://apitest1.thomas-talk.me", //'https://apitest1.thomas-talk.me',//'', 'https://apiclub.thomas-talk.me' 'http://localhost:9001'
  apiTestWS: "wss:///apitest1.thomas-talk.me", //wss://apiclub.thomas-talk.me

  //THOMAS CLUBFIBEX PRODUCCION BASE DE DATOS
  //clubFibexBD: "thomas_clubfibex",
  bd: "thomas_sandbox_clubfibex", // thomas_clubfibex thomas_sandbox_clubfibex
  apiQR: "https://qr.fibextelecom.info",
  //THOMAS CLUBFIBEX DESARROLLO
  clubFibexBD: "thomas_sandbox_clubfibex",
  //bd:"thomas_sandbox_clubfibex",
  NameBDV2: "thomas_colas",
  kioskoApp: false,
  ExternalFibexPlay: true,
  botIAURL: "https://apitest3.thomas-talk.me", //'http://localhost:3000',
  botIAMsgHeader: "BOTIAMSG1.0",
  apiGuia: "https://epgservice.thomas-talk.me/api/guide",
  Lic: "584120202020@c.us",
  LicChat: "appServer1",
  ApiGraphQl: "https://pha.thomas-talk.me/",
  key: "T0rNaDoK4tr1Na?RTgcNmhKU=",
  UrlSubscriptions: "wss://pha.thomas-talk.me/subscriptions",
  URLApi: "https://apitls.fibextelecom.info/", //'https://apitest4.thomas-talk.me/',//'https://apitls.fibextelecom.net/',//, 'https://apitls.fibextelecom.info/'
  URLemails: "https://blast.thomas-talk.me/", // "https://blast.thomas-talk.me/",
  Token: "s1e1/c1r2e-2t.2a3r*3i4a",
  TokenUpdate: "4p1_[51t3m4s]_f186@th0m4s",
  UserBackendFull: "Onur",
  PasswordBackendFull: "L4V1d43NsuPl3N1tud**=BghYjLaQeTB",
  TokenBackendFull:
    "h34BEVSzAijWRFeXBmRGA8FfarVHVLHZo_mfmS7c7TFUjlpMt0LFiI-NKy1tr89pJwpXoPiL2BemcwuDKIuJzeqii-eiZXilqyQYOcm_o1_SE29K5J76E1G-ZY--m0QHdSXvLrR2-dK6I8S7x24pbyzixoSgldSYCulhmooI5Ls0jAr4HS6a",
  URLApi2: "http://localhost:8888/",
  apiMaps: "https://apiamaps.thomas-talk.me/api/v1/",
  apiDbMaps: "https://dbmaps.thomas-talk.me/api/v1/",
  referenciaRecomiendaGana: "https://clientes.fibextelecom.net?abonado=",
  facturasPaginated: "GetFacturasClientePaginated",
  //Token Encript
  apiPhP: "https://login.thomas-talk.me/api/?",
  urlUploadService: "https://img.thomas-talk.me/api/",
  ApiBackendFp: "https://backend-fp.thomas-talk.me/",
  ApiBackendFp_Sus: "wss://backend-fp.thomas-talk.me/subscriptions",
  authdbFUll: "Basic " + btoa("Onur:L4V1d43NsuPl3N1tud**=BghYjLaQeTB"),

  favMethod: "FavApp",
  qrMethod: "GeneratedQR",
  urlZella: "https://update.fibextelecom.net/",
  authZella: "Basic " + btoa("Ruperto:F@r67tEUbDJ%42sYRyn=jSrhsAm4"),
  TokenZella:
    "RdnvSwSzWt1LJ6rjaWivWTqSFh45qINjMH41dZBHpYvsMgoN0wD_RkL71IrYSwMcbxbAIdcXktSYVUWyn5EkFjOF5eDYOuLFyjlSapV0exkqZmd_Bouo8AaAuZrfO9sYKlmXu_kt8lq1O16U6DTEtqkASc05j4iPahraJaDtwQfcVLrAkal5",

  ApiGraphQl2: "http://localhost:4300/",
  UrlSubscriptions2: "ws://localhost:4300/subscriptions",

  apiChatBot: "https://bot.thomas-talk.me/bot/tell-me",
  apiChatBot2: "http://localhost:8094/bot/tell-me",

  ApiImgBot: "https://login.thomas-talk.me/bot/",
  promoUrl: "https://promo.fibextelecom.net/",
  hotspotApi: "https://hotspot.thomas-talk.me/data-microtik/",
  TokenWhatsApp: "L4L3cHuZ@FLI4vHdsf645",
  urlSofia: "https://sofia.fibextelecom.net/chat",

  equipoImagenLista: false,
  apiGrahpic: "https://login.thomas-talk.me/appgraph/",
  apiOlt: "https://api.thomas-talk.me/olt2/",
  apiOltFirst: "https://api.thomas-talk.me/olt/",
  urlThomasApi: "https://api.thomas-talk.me",
  apiResetPassword: "https://login.thomas-talk.me/api/pw/?",
  apiVerifyCedula: "https://login.thomas-talk.me/api/v/?",
  apiFibexPlay: "https://login.thomas-talk.me/api/ci/?",

  apiSecurityQuestions: "https://login.thomas-talk.me/api/p_security/?",
  apiCRM: "https://login.thomas-talk.me/api/",
  tokenTLS: "U2FsdGVkX18okv4KClFVLG6e3zwbDMNyY0u6I3CVdxBwjqyhFjfHAKCJ0GW64YJP",

  permitirPagoMovil: false,
  SSL: "https://apissl.thomas-talk.me/SAE",

  version: "V2.9.26",
  // publicidadUrl: "http://pubicidadkiosco.thomas-talk.me/",
  publicidadUrl: "https://promo.fibextelecom.net/kiosko/",
  ApiBackendThomas: "https://dbfull.thomas-talk.me/api/", // https://dbfull.thomas-talk.me/api/
  NameBD: "thomas_cobertura",

  //OneSignal
  one_signal_tbl: "one_signal_push",

  type_specific_search: "find-any-info",
  type_specific_to_update: "update-info",

  field_specific_info: "abonado",
  field_specific_info2: "plataform",
  field_specific_info_to_update: "id_one_signal",

  tableNotify: "tm_notifications_clients", //tm_notifications_clients tm_notifications_clients_sandbox
  tableBanner: "tm_LandingBanner", //tm_notifications_clients tm_notifications_clients_sandbox
  tableReferidos: "tmReferidos",
  facturas: "GetFacturasCliente",
  banners: "Banners_App",
  getpassword: "GetDataUser",

  //! ChatBot
  botAPI: "https://bot.thomas-talk.me/bot/tell-me",

  // ! Facturas
  pc_method: "IntermediaryW",
  pc_platform: "BackendMercantil",
  versionAPK: "20.80.43",

  //! hash info
  hash_url: "https://apiext.thomas-talk.me/",
  hash_token:
    "SpOgMuwsvIpT4zjn-c1FcRPOf7_fdUbPmNXs4ktmUfjV_gW8rFJpvR71eR6Bojv7gROJrZ79NU0pVKbcL3IMhoQID-NFvbuxVSfZbsrCBz8f_dVLtlY_V0Dufug7UMAYNX83xLMIU9D4a7mfyLYBTNmUn5dXVYWdrVnQkNbZwhkm102yRMkH",
  hash_user: "Chechelev",
  hash_password: "Jky/6#()[}NgEasGHtIr=456sdw5HHd",

  //!Saeplus cnn
  database: "saeplus_conexven",

  //! Token upload images
  upload_image_url: "https://images-services.thomas-talk.me",
  upload_image_token:
    "bT5n-xuJ4JXcxIGvVsF2ZmffHg3I_h2OLcon6UvZ9kBxNAzlDIoQFmFpOWkU5yU4Z0R0GbTjK-5bXpv7PAtw6h2BlYJ2DddJKi9A5GouXgCpoxfNTbOMVwmiNAvCvDFJsULA3q-rqgESUgR9Rk4g61ttRV_1HCv-vl5-tj3lODHTtLK2_hGh",
  upload_image_user: "Randal",
  upload_image_password: "$%rgY6rh==vbhp93*",
  upload_image_endpoint: "upload-images",
  folder: "Factura-Estado-Cuenta",

  // ! Metodos ApiSsl
  changeService: "ChangeService",
  servicios: "ServiciosIdCo",
  serviciosChange: "ChangePlanUpgrade",
  serviciosDisponibles: "ServicesAvailable",

  //recomienda y gana
  scoreTBL: "tmPuntosClientes",
  tblReferral: "tmReferrals",

  platform: "App",

  config: {
    // iconMode: 'md',
    // mode: 'md'
    // iconMode: 'ios',
    // mode: 'ios'
    // preloadModules: true,
    // scrollPadding: false,
    // scrollAssist: true,
    autoFocusAssist: false,
    menuType: "overlay",
  },
  // Set language to use.
  language: "en",
  // Loading Configuration.
  // Please refer to the official Loading documentation here: https://ionicframework.com/docs/api/components/loading/LoadingController/
  loading: {
    spinner: "circles",
  },
  // Toast Configuration.
  // Please refer to the official Toast documentation here: https://ionicframework.com/docs/api/components/toast/ToastController/
  toast: {
    position: "bottom", // Position of Toast, top, middle, or bottom.
  },
  toastDuration: 3000, // Duration (in milliseconds) of how long toast messages should show before they are hidden.
  // Angular Google Maps Styles Config
  agmStyles: [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#1d2c4d",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#8ec3b9",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#1a3646",
        },
      ],
    },
    {
      featureType: "administrative.country",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#4b6878",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#64779e",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#4b6878",
        },
      ],
    },
    {
      featureType: "landscape.man_made",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#334e87",
        },
      ],
    },
    {
      featureType: "landscape.natural",
      elementType: "geometry",
      stylers: [
        {
          color: "#023e58",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#283d6a",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#6f9ba5",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#1d2c4d",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#023e58",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#3C7680",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#304a7d",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#98a5be",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#1d2c4d",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#2c6675",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#255763",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#b0d5ce",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#023e58",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#98a5be",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#1d2c4d",
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#283d6a",
        },
      ],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#3a4762",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#0e1626",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#4e6d70",
        },
      ],
    },
  ],
  // //
};
