import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../environments/environment';
import { IOneSignal } from '../interfaces/IOneSignal';
import { SeguridadDatos } from './bscript.service';
import { Subject, Observable } from 'rxjs';
// import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class OneSignalService {
  dni: any;
  device$ = new EventEmitter<string>();
  private sign$ = new Subject<any>();
  
  constructor(
    private http: HttpClient,
    private security: SeguridadDatos,
    // private alert: AlertController
  ) { }

  oneSignalPost(OneSignal: IOneSignal){
    const headers ={
      'TokenAuthPlataform': env.TokenBackendFull,
      'db' : this.security.encrypt(env.NameBD),
      'table': this.security.encrypt(env.one_signal_tbl),
      'Authorization': `${'Basic ' + btoa(`${env.UserBackendFull}:${env.PasswordBackendFull}`)}`,
      'x-keys-to-add-id': '["idstr"]', 
      'x-keys-of-arrays': '[]', 
      'x-relations': 'false', 
      'Content-Type': 'application/json'
    }

    // this.alert.create({
    //   message: 
    //   JSON.stringify(OneSignal, null, "  "),
    //   header: "body",
    //   inputs: [
    //     {
    //       type: "text",
    //       value: JSON.stringify(OneSignal, null, "  "),
    //     }
    //   ]
    // })
    //   .then((a) => a.present());
    // this.alert.create({
    //   message: 
    //   JSON.stringify(headers, null, "  "),
    //   header: "header",
    //   inputs: [
    //     {
    //       type: "text",
    //       value: JSON.stringify(headers, null, "  "),
    //     }
    //   ]
    // })
    //   .then((a) => a.present());

    // window.alert(
    //   "HEADERS: \n" +
    //   JSON.stringify(headers, null, "  ")
    // );

    this.http.post(`${env.ApiBackendThomas}create-info`, OneSignal, {headers}).subscribe(res => {
      // window.alert("data:\n\n" + JSON.stringify(res, null, "  "));
        // this.alert.create({
        //     message: `REGISTRO SUCCESS
            
        //     ${res}
        //     `
        //   }).then(res => res.present())
    }, (err) => {
      // window.alert("CATCH ERROR:\n\n" + JSON.stringify(err, null, "  "));
        // this.alert.create({
        //     message: `REGISTRO ERROR
            
        //     ${err}
        //     `
        //   }).then(res => res.present())
    });
  }

  oneSignalGet(subscriberNumb:string, platform: string){
    const headers ={
      'TokenAuthPlataform': env.TokenBackendFull,
      'db' : this.security.encrypt(env.NameBD),
      'table': this.security.encrypt(env.one_signal_tbl),
      'type':this.security.encrypt(env.type_specific_search),
      'campo':this.security.encrypt(env.field_specific_info),
      'valor':this.security.encrypt(subscriberNumb),
      'campo2':this.security.encrypt(env.field_specific_info2),
      'valor2':this.security.encrypt(platform),
      'Authorization': `${'Basic ' + btoa(`${env.UserBackendFull}:${env.PasswordBackendFull}`)}`,
      'Content-Type': 'application/json'
    }

    return this.http.get(env.ApiBackendThomas,{headers})
  }

  async oneSignalPut(OneSignal: IOneSignal){
    const headers ={
      'TokenAuthPlataform': env.TokenBackendFull,
      'db' : this.security.encrypt(env.NameBD),
      'table': this.security.encrypt(env.one_signal_tbl),
      'campo':this.security.encrypt(env.field_specific_info),
      'campo2':this.security.encrypt(env.field_specific_info2),
      'valor': this.security.encrypt(OneSignal.abonado),
      'Authorization': `${'Basic ' + btoa(`${env.UserBackendFull}:${env.PasswordBackendFull}`)}`,
      'x-multiple-update': 'false',
      'x-elements-obj': '[]',
      'x-attr-duplicate': '[]',
      'Content-Type': 'application/json'
    }

    this.http.put(`${env.ApiBackendThomas}update-info`, OneSignal, {headers}).subscribe(res => console.log(res));
  }

  emitSign(){
    this.sign$.next('logout');
  }

  getSign(): Observable<any>{
    return this.sign$.asObservable();
  }
}
